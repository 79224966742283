import LoadingIcon from '../images/loading.svg';
import '../styles/components/_loading.scss';

import { lazy, Suspense, useEffect, useState } from 'react';
import '../styles/pages/home.scss';

import Design from '../images/icons/design.svg';
import DesignSite from '../images/icons/design-site.svg';
import Mobile from '../images/icons/cellphone.svg';
import Hosting from '../images/icons/hosting.svg';
import Systems from '../images/icons/systems.svg';
import api from '../services/api.js';
import { scrollTo } from '../helpers/utils.js';

const Banner = lazy(() => import('../components/Banner/Banner.js'));
const Services = lazy(() => import('../components/Services/Services.js'));
const About = lazy(() => import('../components/About/About.js'));
const Contact = lazy(() => import('../components/Contact/Contact.js'));
const Footer = lazy(() => import('../components/Footer/Footer.js'));
const Navigation = lazy(() => import('../components/Navigation/Navigation.js'));

const Loading = () => {
  return (
    <main className="loading">
      <img src={LoadingIcon} alt="" className="loading-icon" />
    </main>
  )
}

export default function Home() {
  //  DEFININDO VARIÁVEIS PARA O FORMULÁRIO DE CONTATO
  var [subject, setSubject] = useState({
    title: "Design",
    icon: Design,
    color: "purple",
    description: "Quero um novo visual para meu site/marca"
  });

  //  KEY PARA ATUALIZAR O FORMULÁRIO DE CONTATO
  var [seed, setSeed] = useState(1);

  //  DEFININDO VARIÁVEIS DA MÚSICA
  var [music, setMusic] = useState({
    music: "",
    lyrics: "",
    band: "",
    link: ""
  })

  function changeSubject(clickedElement, scrollToContact = false) {
    var valueToChange = clickedElement.dataset.value;

    var actualSubject = subject;

    actualSubject.title = valueToChange;

    switch (valueToChange) {
      case "Design":
        actualSubject.icon = Design;
        actualSubject.description = "Quero um novo visual para meu site/marca";
        actualSubject.color = "purple";
        break;
      case "Site ou Landing Page":
        actualSubject.icon = DesignSite;
        actualSubject.description = "Quero um site ou landing page para promove meu negócio";
        actualSubject.color = "blue";
        break;
      case "Sistema":
        actualSubject.icon = Systems;
        actualSubject.description = "Quero um sistema para gerenciar meu negócio";
        actualSubject.color = "cyan";
        break;
      case "Aplicativo Mobile":
        actualSubject.icon = Mobile;
        actualSubject.description = "Quero um aplicativo mobile";
        actualSubject.color = "yellow";
        break;
      case "Hospedagem de Site e Email":
        actualSubject.icon = Hosting;
        actualSubject.description = "Quero um serviço de hospedagem e email";
        actualSubject.color = "red";
      break;
    }
    
    setSubject(actualSubject);
    
    if (scrollToContact)
    {
      setSeed(Math.random());
      scrollTo("#contato");
    }
  }

  async function loadLyrics() {
		try {
			let retorno = await api.get('/musicas/aleatoria');
			setMusic(retorno.data);
		} catch (err) {
			console.log(`Falha, tente novamente mais tarde`);
		}
	}

	useEffect(() => {
    loadLyrics();
  }, []);

	return (
		<main className="home-page">
			<Suspense fallback={Loading()}>
				<Navigation />
				<Banner />
				<Services changeSubject={changeSubject} />
				<About />
				<Contact key={seed} changeSubjectEmmiter={changeSubject} subject={subject} music={music}/>
				<Footer />
			</Suspense>
		</main>
	);
}
